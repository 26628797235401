import React from "react"

import { graphql } from "gatsby"
import {Link, useI18next, useTranslation} from "gatsby-plugin-react-i18next"

import Layout from "/src/components/layout"
import ContentLayout from "/src/components/content-layout"
import Seo from "/src/components/seo"
import AnchorLink  from "/src/components/link-anchor"
import Assets  from "/src/components/link-assets"
import AssetsDirect  from "/src/components/link-assets-direct"
import External from "/src/components/link-external"
import DataList from "/src/components/datalist"
import DataListRow from "/src/components/datalist-row"

import toppic03 from "/src/images/top-pic-03.png"
import experiencepic04 from "/src/images/experience-pic-04.jpg"

const Page = ({data}) => {
  const bredcrumb = [
    {
      pathname: '/',
      crumbLabel: 'Home'
    },
  ]

  const {t} = useTranslation();
  const lang = data.locales.edges[0] ? data.locales.edges[0].node.language : 'ja' ;

  const html01 = getBlock01(lang);
  const html01ja = getBlock01ja(lang);
  const html03 = getBlock03(lang);
  const html04 = getBlock04(lang);
  

  return(
    <Layout bredcrumb={bredcrumb} lang={lang}>
      <Seo title={t("研究成果の普及")} lang={lang}/>
      <ContentLayout lang={lang}>
        <h1>{t("研究成果の普及")}</h1>
        <ul className="anchorlink">
          <li><AnchorLink anchor="#anchor01" >{t("見学案内")}</AnchorLink></li>
          <li><AnchorLink anchor="#anchor02" >{t("一般公開")}</AnchorLink></li>
          <li><AnchorLink anchor="#anchor03" >{t("ちびっこ博士")}</AnchorLink></li>
          <li><AnchorLink anchor="#anchor04" >{t("おとなのためのサイエンス講座")}</AnchorLink></li>
          
        </ul>
        <img src={ toppic03 } alt=""  className="img-wide" />


        <h2 id="anchor01">{t("見学案内")}</h2>
        <div className="image-text-layout">
          <div className="txt-box">
            <p>
              {t("バイオリソース研究センターでは、バイオリソースや事業の活動をご覧いただく施設見学を行っています。様々なバイオリソースを映像でご紹介しながら、細胞貯蔵施設等をスタッフがご案内します。どうぞお越しください。")}
            </p>
          </div>
          <img src={ experiencepic04 } alt=""  className="img-box"/>
        </div>

        {html01}


        <DataList>
          <DataListRow title={t("見学時間")}>{t("10:00 - 16:00")}</DataListRow>
          <DataListRow title={t("見学日")}>
            {t("月曜日、水曜日～金曜日")}<br />
            {t("（毎週火曜日、土日祝祭日、年末年始を除く）")}
          </DataListRow>
        </DataList>

        {html01ja}




        <h2 id="anchor02">{t("一般公開")}</h2>
        <p>
          {t("理化学研究所筑波地区では皆様に科学に親しんでいただくため、年に1回一般公開を開催しております。研究者による講演や多彩なイベントを通じて、普段見ることのできない研究所の様子をお楽しみください。")}
        </p>

        <h2 id="anchor03">{t("ちびっこ博士")}</h2>
        <p>
          {t("つくばちびっこ博士は、つくば市の児童生徒が「最優秀つくばちびっこ博士」を目指し、つくば市内にある研究機関で行う展示やイベントに参加する科学体験イベントです。バイオリソース研究センターでもこのイベントに参加し、科学にふれあう楽しい場を提供しています。")}
        </p>

        {html03}

        <p className="txt-center">
          <External 
            href="https://www.youtube.com/watch?v=f7IbSJ7z9T8"
            btn="2"
            >
            {t("つくばちびっこ博士(2023)")}
          </External>
        </p>



        <h2 id="anchor04">{t("おとなのためのサイエンス講座")}</h2>
        <p>
          {t("つくばエキスポセンターが主催する、科学技術に関心のある大人を対象に、座学と実習を組み合わせた「おとなのためのサイエンス講座」として年に数コマの1回2時間の講義を行い、実験もBRCに集まっていただき実施します。")}
        </p>
        {html04}
        <p className="txt-center">
          <External 
            href="http://www.expocenter.or.jp"
            btn="2"
            >
            {t("つくばエキスポセンター")}
          </External>
        </p>

        

      </ContentLayout>
    </Layout>
  )
}

export default Page


export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    assets:allFile(filter: {sourceInstanceName: {eq: "assets"}}) {
      edges {
        node {
          base
          name
          extension
          prettySize
        }
      }
    }
  }
`


function getBlock01(language) {
  if(language==='en'){
    return (
      <p className="annotation">Please note that tours are available in Japanese only.</p>
    )
  }else{
    return (
      <></>
    )
  }
}
function getBlock01ja(language) {
  if(language==='ja'){
    return (
      <p className="txt-center">
        <Link className="btn btn01" to="/experience/application/">見学お申込み</Link>
      </p>
    )
  }else{
    return (
      <></>
    )
  }
}

function getBlock03(language) {
  if(language==='en'){
    return (
      <p className="annotation">Please note that the event is available in Japanese only.</p>
    )
  }else{
    return (
      <></>
    )
  }
}
function getBlock04(language) {
  if(language==='en'){
    return (
      <p className="annotation">Please note that the courses are available in Japanese only.</p>
    )
  }else{
    return (
      <></>
    )
  }
}

